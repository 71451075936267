

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom scrollbar styling */
/* Custom scrollbar styling */
.scrollbar-custom {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #f0f0f0 white; /* thumb color and track color */
 
}

.scrollbar-custom::-webkit-scrollbar {
  width: 2px; /* Width of the entire scrollbar */
  height: 3px; /* Height of the entire scrollbar */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: white; /* Color of the scrollbar track */
  border-radius: 10px; /* Roundness of the scrollbar track */
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 2px solid transparent; /* Padding around the scrollbar thumb */
  background-clip: padding-box;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}

:root {
  --angleStrong: 45deg; /* Default gradient angle */
}

@media (max-width: 768px) {
  :root {
    --angleStrong: 90deg; /* Adjusted gradient angle for smaller screens */
  }
}
