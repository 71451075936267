
.gradient-div-home {
  width: 150%;
  height: 81vh; /* 1/3rd of the viewport height */
  background: linear-gradient(30deg,  #E10600,#0CB6D8 ,#FFD100);
  background-size: 150% 150%;
  animation: gradientAnimation 3s ease infinite;
  position: fixed;
  bottom: 0;
	z-index: -1;
  transform: rotate(-5deg);
  transform-origin: bottom left;
}

@media (max-width: 745px) {
  .gradient-div-home-mobile {
    width: 100%;
    height: 100vh;
    background: linear-gradient(100deg, #E10600, #0CB6D8, #FFD100);
    background-size: 150% 150%;
    animation: gradientAnimation 3s ease infinite;
    bottom: 0;
    z-index: -1;
    transform: rotate(0deg);
    transform-origin: bottom left;
  }
}


  .Gradient__canvas {
    z-index: 100;
    width: 80%;
    height: 100vh;
    background: linear-gradient(90deg,  #E10600,#FFD100,#0CB6D8);
    background-size: 100% 100%;
    animation: gradientAnimation 1s ease infinite;
  }

  .isLoaded {
    /* Additional styles when the element is loaded */
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    25% {
      background-position: 50% 25%;
    }
    50% {
      background-position: 100% 50%;
    }

    75% {
      background-position: 100% 75%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
